import React from "react";
import { Box } from "@mui/material";
import Header from "@/components/Header";

const Home = () => {
  return (
    <Box m="20px">
      <Header title="BIENVENUE" subtitle="La page Home" />
    </Box>
  );
};

export default Home;
