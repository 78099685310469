import { useState, useEffect } from "react";
import cnf from "@/config.json";
import { useGetObjetsQuery } from "@/features/objets/objetsSlice";
import { useGetRelationsQuery } from "@/features/relations/relationsSlice";
import {
  ListItem,
  Paper,
  ListItemButton,
  ListItemText,
  IconButton,
  Box,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable as Droppable } from "@/_helpers/StrictModeDroppable";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserObjetSelected,
  selectUserObjetSelected,
  setIsEditingObjet,
} from "@/state/userSlice";
import {
  useDeleteObjetMutation,
  useReorderObjetsMutation,
} from "@/features/objets/objetsSlice";
import {
  useReorderRelationsMutation,
  useUnlinkRelationsMutation,
} from "@/features/relations/relationsSlice";
const RelationsListReordarable = (props) => {
  const dispatch = useDispatch();
  const userObjetSelected = useSelector(selectUserObjetSelected);

  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleListObjetClick = (event, index, objet) => {
    if (objet != userObjetSelected) dispatch(setUserObjetSelected(objet));
    setSelectedIndex(index);
    dispatch(setIsEditingObjet(false));
  };

  const handleEditObjetClick = (event, index, objet) => {
    dispatch(setIsEditingObjet(objet.id));
  };

  const [deleteObjet] = useDeleteObjetMutation();

  const handleDeleteObjetClick = async (event, index, objet) => {
    try {
      await deleteObjet({
        user_section_id: props.userSection.id,
        id: userObjetSelected.id,
      }).unwrap();
      props.emptyObjet();
      refetchRelations();
      toast.success(cnf.TXT.TOAST_SUCCESS);
    } catch (err) {
      toast.error(cnf.TXT.TOAST_ERROR);
    }
  };

  const handleAddObjetClick = () => {
    setSelectedIndex(0);
    props.handleAddObjetClick();
  };

  const [unlinkRelations] = useUnlinkRelationsMutation();

  const handleUnlinkRelationsClick = async (event, categorieId, itemId) => {
    try {
      await unlinkRelations({
        user_section_id: props.userSection.id,
        section_id: props.to_section_id,
        categorie_id: categorieId,
        item_id: itemId,
      }).unwrap();
      props.emptyObjet();
      refetchRelations();
      toast.success(cnf.TXT.TOAST_SUCCESS);
    } catch (err) {
      toast.error(cnf.TXT.TOAST_ERROR);
    }
  };

  const {
    data: objets,
    refetch: refetchObjets,
    isLoading: isLoadingObjets,
    isSuccess: isSuccessObjets,
    isError: isErrorObjets,
    error: errorObjets,
  } = useGetObjetsQuery(props.userSection?.id);

  const deleteCondition = true;

  const [objetsReordered, setObjetsReordered] = useState(objets || []);

  useEffect(() => {
    setObjetsReordered(objets);
  }, [objets]);

  const [reorderObjetsMutation] = useReorderObjetsMutation();

  const handleOnDragEnd = async (result) => {
    setIsDragging(false);

    // Manipulation de l'ordre des items et des références de parent en fonction du résultat du glisser-déposer
    // Met à jour les données dans le backend via une mutation RTK Query
    // Recharge les données après la mise à jour pour refléter les changements à l'écran

    if (!result.destination) {
      return; // Le glisser-déposer n'a pas abouti dans une zone de dépôt valide
    }

    const { source, destination } = result;
    if (source.index === destination.index) {
      return; // L'ordre n'a pas changé
    }

    // Réorganiser les éléments dans la liste en fonction du glisser-déposer
    const reorderedIds = Array.from(objetsReordered.ids);
    const [removed] = reorderedIds.splice(source.index, 1);
    reorderedIds.splice(destination.index, 0, removed);

    // Conserver l'état précedent si l'appel back est KO et qu'il faut donc revenir à l'état précédent
    const previous_objetsReordered = objetsReordered;

    // Mettre à jour l'état pour garder l'affichage réordonné
    setObjetsReordered({
      ids: reorderedIds,
      entities: objets.entities,
    });

    try {
      await reorderObjetsMutation({
        user_section_id: props.userSection.id,
        data: reorderedIds,
      }).unwrap();
      toast.success(cnf.TXT.TOAST_SUCCESS);
    } catch (err) {
      console.log(err);
      setObjetsReordered(previous_objetsReordered); // pour afficher l'ancien ordre vu que l'API tombe en erreur
      toast.error(cnf.TXT.TOAST_ERROR);
    }
  };

  /** Pour que la section prenne 100% de la hauteur de l'écran restant */
  const heightAdjustment = props.heightAdjustment ?? "";
  const [containerHeight, setContainerHeight] = useState(
    `calc(100vh - 128px${heightAdjustment})`
  ); // Hauteur initiale à 100vh
  useEffect(() => {
    const handleResize = () => {
      // setContainerHeight(`${window.innerHeight - 130}px`); // Met à jour la hauteur du conteneur en fonction de la taille de la fenêtre
      setContainerHeight(
        `calc(${window.innerHeight} - 128px${heightAdjustment})`
      ); // Met à jour la hauteur du conteneur en fonction de la taille de la fenêtre
    };

    window.addEventListener("resize", handleResize); // Ajoute un écouteur d'événement pour la redimension de la fenêtre

    return () => {
      window.removeEventListener("resize", handleResize); // Nettoie l'écouteur d'événement lors du démontage du composant
    };
  }, []);

  /** Pour qu'on puisse scroll dans la liste pendant qu'on drag and drop   */
  const [isDragging, setIsDragging] = useState(false);
  const handleOnDragStart = () => {
    setIsDragging(true);
  };

  const displayValues = (objet) => {
    let result = "";
    for (let key in objet) {
      if (objet.hasOwnProperty(key)) {
        result += key + ": " + objet[key] + " | ";
      }
    }

    return result;
  };

  /** Début relations */
  const {
    data: dataRelations,
    refetch: refetchRelations,
    isLoading: isLoadingRelations,
    isSuccess: isSuccessRelations,
    isError: isErrorRelations,
    error: errorRelations,
  } = useGetRelationsQuery({
    user_section_id: props.userSection?.id,
    section_id: props.to_section_id,
  });

  const [stateRelations, setStateRelations] = useState(dataRelations || []);

  const [reorderRelationsMutation] = useReorderRelationsMutation();

  useEffect(() => {
    refetchRelations();
    setStateRelations(dataRelations);
  }, [dataRelations]);

  useEffect(() => {
    refetchRelations();
  }, [objetsReordered]);

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    // Vérifier si la destination est valide
    if (!destination) return;

    // Récupérer l'objet source et la catégorie source
    const sourceCategorieId = parseInt(source.droppableId);

    const sourceCategorie = stateRelations?.entities[sourceCategorieId];
    const sourceItem = sourceCategorie.ids[source.index];

    // Conserver l'état précedent si l'appel back est KO et qu'il faut donc revenir à l'état précédent
    const previous_stateRelations = stateRelations;

    // Vérifier si le glisser-déposer se fait dans la même catégorie
    if (source.droppableId === destination.droppableId) {
      // Mettre à jour l'ordre des items dans la même catégorie
      const updatedIds = Array.from(sourceCategorie.ids);
      updatedIds.splice(source.index, 1);
      updatedIds.splice(destination.index, 0, sourceItem);

      // Mettre à jour les données avec les modifications
      setStateRelations((prevStateRelations) => ({
        ...prevStateRelations,
        entities: {
          ...prevStateRelations.entities,
          [sourceCategorieId]: {
            ...sourceCategorie,
            ids: updatedIds,
          },
        },
      }));

      try {
        await reorderRelationsMutation({
          user_section_id: props.userSection.id,
          section_id: props.to_section_id,
          source_categorie_id: source.droppableId,
          destination_categorie_id: destination.droppableId,
          reordered_source_ids: updatedIds,
          reordered_destination_ids: updatedIds,
        }).unwrap();
        toast.success(cnf.TXT.TOAST_SUCCESS);
      } catch (err) {
        console.log(err);
        setStateRelations(previous_stateRelations); // pour afficher l'ancien ordre vu que l'API tombe en erreur
        toast.error(cnf.TXT.TOAST_ERROR);
      }
    } else {
      // Déplacer l'item vers une autre catégorie
      const destinationCategorieId = parseInt(destination.droppableId);
      const destinationCategorie =
        stateRelations?.entities[destinationCategorieId];

      const updatedSourceIds = Array.from(sourceCategorie.ids);
      updatedSourceIds.splice(source.index, 1);

      const updatedDestinationIds = Array.from(destinationCategorie.ids);
      updatedDestinationIds.splice(destination.index, 0, sourceItem);

      // Mettre à jour les données avec les modifications
      setStateRelations((prevStateRelations) => ({
        ids: prevStateRelations.ids,
        entities: {
          ...prevStateRelations.entities,
          [sourceCategorieId]: {
            ...sourceCategorie,
            ids: updatedSourceIds,
          },
          [destinationCategorieId]: {
            ...destinationCategorie,
            ids: updatedDestinationIds,
          },
        },
      }));

      try {
        await reorderRelationsMutation({
          user_section_id: props.userSection.id,
          section_id: props.to_section_id,
          source_categorie_id: source.droppableId,
          destination_categorie_id: destination.droppableId,
          reordered_source_ids: updatedSourceIds,
          reordered_destination_ids: updatedDestinationIds,
        }).unwrap();
        toast.success(cnf.TXT.TOAST_SUCCESS);
      } catch (err) {
        console.log(err);
        setStateRelations(previous_stateRelations); // pour afficher l'ancien ordre vu que l'API tombe en erreur
        toast.error(cnf.TXT.TOAST_ERROR);
      }
    }
  };
  /** Fin Relations */

  let content;
  if (isLoadingObjets || isLoadingRelations) {
    content = <p>Loading...</p>;
  } else if (isSuccessObjets && isSuccessRelations) {
    content = (
      <>
        <Box display="flex" justifyContent="end" mb="20px">
          <Button
            type="submit"
            color="secondary"
            variant="outlined"
            disabled={selectedIndex === 0 ? true : false}
            endIcon={<AddIcon />}
            onClick={handleAddObjetClick}
          >
            Ajouter
          </Button>
        </Box>
        <DragDropContext onDragEnd={onDragEnd}>
          <section
            style={{ height: containerHeight, overflow: "auto" }}
            onScroll={(e) => e.stopPropagation()}
          >
            {stateRelations?.ids?.map((categorieId) => (
              <Droppable
                droppableId={categorieId.toString()}
                key={categorieId}
                isDropDisabled={categorieId === 0}
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <h3>
                      {categorieId
                        ? stateRelations.entities[categorieId].name
                          ? `${stateRelations.entities[categorieId].name}`
                          : "Catégorie " + categorieId
                        : `Item(s) non assigné(s)`}
                    </h3>
                    {stateRelations.entities[categorieId].ids.map(
                      (itemId, index) => (
                        <Draggable
                          key={itemId.toString()}
                          draggableId={itemId.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Paper elevation={0} sx={{ mb: 0.5 }}>
                                <ListItem
                                  disablePadding
                                  key={itemId}
                                  {...(deleteCondition &&
                                    selectedIndex === itemId && {
                                      secondaryAction: (
                                        <>
                                          <IconButton
                                            edge="end"
                                            aria-label="edit"
                                            sx={{ ml: 2 }}
                                            onClick={(event) =>
                                              handleUnlinkRelationsClick(
                                                event,
                                                categorieId,
                                                itemId
                                              )
                                            }
                                          >
                                            <LinkOffIcon />
                                          </IconButton>
                                          <IconButton
                                            edge="end"
                                            aria-label="edit"
                                            sx={{ ml: 2 }}
                                            onClick={(event) =>
                                              handleEditObjetClick(
                                                event,
                                                itemId,
                                                objets.entities[itemId]
                                              )
                                            }
                                          >
                                            <ModeEditOutlineIcon />
                                          </IconButton>
                                          <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            sx={{ ml: 2 }}
                                            onClick={(event) =>
                                              handleDeleteObjetClick(
                                                event,
                                                itemId,
                                                objets.entities[itemId]
                                              )
                                            }
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </>
                                      ),
                                    })}
                                >
                                  <ListItemButton
                                    selected={selectedIndex === itemId}
                                    onClick={(event) =>
                                      handleListObjetClick(
                                        event,
                                        itemId,
                                        objets.entities[itemId]
                                      )
                                    }
                                  >
                                    <ListItemText
                                      sx={{ mr: 3 }}
                                      primary={displayValues(
                                        objets.entities[itemId]
                                      )}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              </Paper>
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </section>
        </DragDropContext>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    );
  } else if (isErrorObjets) {
    content = <p>{errorObjets}</p>;
  }

  return content;
};

export default RelationsListReordarable;
