import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  connected: null,
  userApplicationSelected: null,
  userSectionSelected: null,
  userObjetSelected: {
    id: "",
    name: "",
  },
  isEditingObjet: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setConnected: (state, action) => {
      state.connected = action.payload;
      state.userApplicationSelected = null;
      state.userSectionSelected = null;
      state.userObjetSelected = {
        id: "",
        name: "",
      };
    },
    setDisconnected: (state) => {
      state.connected = null;
      state.userApplicationSelected = null;
      state.userSectionSelected = null;
      state.userObjetSelected = {
        id: "",
        name: "",
      };
    },
    setUserApplicationSelected: (state, action) => {
      state.userApplicationSelected = action.payload;
      state.userSectionSelected = null;
      state.userObjetSelected = {
        id: "",
        name: "",
      };
    },
    setUserSectionSelected: (state, action) => {
      state.userSectionSelected = action.payload;
      state.userObjetSelected = {
        id: "",
        name: "",
      };
    },
    setUserObjetSelected: (state, action) => {
      state.userObjetSelected = action.payload;
    },
    setIsEditingObjet: (state, action) => {
      state.isEditingObjet = action.payload;
    },
  },
});

export const {
  setConnected,
  setDisconnected,
  setUserApplicationSelected,
  setUserSectionSelected,
  setUserObjetSelected,
  setIsEditingObjet,
} = userSlice.actions;

export default userSlice.reducer;

export const selectEmail = (state) => state.user.connected?.email;

export const selectUserApplications = (state) =>
  state.user.connected?.userApplications;

export const selectUserApplicationSelected = (state) =>
  state.user.userApplicationSelected;

export const selectUserSectionSelected = (state) =>
  state.user.userSectionSelected;

export const selectUserObjetSelected = (state) => state.user.userObjetSelected;

export const selectIsEditingObjet = (state) => state.user.isEditingObjet;
