import Axios from "./caller.service";

/**
 * Récupération de la liste des utilisateurs
 * @returns {Promise}
 */
let getAllUsers = () => {
  return Axios.get("/users");
};

/**
 * Récupération d'un utilisateur
 * @param {number} uid
 * @returns {Promise}
 */
let getUser = (uid) => {
  return Axios.get("/users/" + uid);
};

/**
 * Récupération d'un utilisateur par son email
 * @param {number} uid
 * @returns {Promise}
 */
let getUserByEmail = (email) => {
  return Axios.get("/users?email=" + email);
};

/**
 * Récupération d'un utilisateur par son email
 * @param {number} uid
 * @returns {Promise}
 */
let getMe = () => {
  return Axios.get("/me");
};

/**
 * Ajout d'un utilisateur
 * @param {number} user
 * @returns {Promise}
 */
let addUser = (user) => {
  return Axios.put("/users", user);
};

/**
 * Mise à jour d'un utilisateur
 * @param {number} user
 * @returns {Promise}
 */
let updateUser = (user) => {
  return Axios.patch("/users/" + user.id, user);
};

/**
 * Suppression d'un utilisateur
 * @param {number} uid
 * @returns {Promise}
 */
let deleteUser = (uid) => {
  return Axios.delete("/users/" + uid);
};

/**
 * Récupération de l'utilisateur connecté
 * @param {number} uid
 * @returns {Promise}
 */
let getUserLogged = () => {
  return Axios.get("/logged");
};

// Déclaration des services pour import
export const userService = {
  getAllUsers,
  getUser,
  getUserByEmail,
  getMe,
  addUser,
  updateUser,
  deleteUser,
  getUserLogged,
};
