import React from "react";
import { useState } from "react";
import { Outlet } from "react-router-dom";

import "./admin.css";

import Topbar from "@/pages/Admin/global/Topbar";
import Sidebar from "@/pages/Admin/global/Sidebar";
import { useSelector } from "react-redux";
import { selectUserSectionSelected } from "@/state/userSlice";

const AdminLayout = () => {
  const userSectionSelected = useSelector(selectUserSectionSelected);

  const [isSidebar, setIsSidebar] = useState(true);
  return (
    <>
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar
          setIsSidebar={setIsSidebar}
          userSectionSelectedLabel={userSectionSelected?.label}
        />
        <Outlet />
      </main>
    </>
  );
};

export default AdminLayout;
