import { useEffect, createRef, useState } from "react";
import cnf from "@/config.json";
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Switch,
  Select,
  FormHelperText,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Typography,
  Checkbox,
  Slider,
  Autocomplete,
  Chip,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik, Field } from "formik";
import * as yup from "yup";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { useAddNewObjetMutation, useUpdateObjetMutation } from "./objetsSlice";
import { useSelector } from "react-redux";
import {
  selectUserObjetSelected,
  selectIsEditingObjet,
} from "@/state/userSlice";

const placeholder = "";

const options = [
  {
    label: "un",
    value: "11",
  },
  {
    label: "deux",
    value: "22",
  },
  {
    label: "trois",
    value: "33",
  },
];

const ObjetField = ({
  isReadOnly,
  index,
  type,
  label,
  name,
  value,
  handleBlur,
  handleChange,
  error,
  helperText,
  val,
}) => {
  val = val ? val : options; // dans les champs à liste de valeurs, on affiche les valeurs par défaut (un, deux, trois) si les "val" ne sont pas récupérés de la bdd
  return (
    // On vérifie que le type ne correspond aux types suivants avant d'afficher la Box (pour éviter les espaces vides de la Box)
    !["button", "hidden", "image", "reset", "search", "submit"].includes(
      type
    ) && (
      <Box>
        {(() => {
          if (type == "slider") {
            value = value ? value : 0;
          } else if (type == "autocomplete") {
            value = value
              ? value
              : {
                  label: "",
                  value: "",
                };
          } else if (type == "switch") {
            value = value ? true : false;
          } else {
            value = value ? value : ""; // nécessaire pour certain champ (ex: date, select, radio)
          }

          const shrinkLabel = [
            "switch",
            "date",
            "select",
            "multiselect",
            "color",
            "datetime-local",
            "file",
            "month",
            "range",
            "time",
            "week",
          ].includes(type)
            ? true
            : Boolean(value);

          switch (type) {
            // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attributes
            case "color":
            case "date":
            case "datetime-local":
            case "email":
            case "file":
            case "month":
            case "number":
            case "password":
            case "range":
            case "tel":
            case "text":
            case "time":
            case "url":
            case "week":
              return (
                <TextField
                  helperText={helperText}
                  label={label}
                  value={value}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isReadOnly}
                  fullWidth
                  key={index}
                  InputLabelProps={{ shrink: shrinkLabel }}
                  color="secondary"
                  variant="outlined"
                  type={type}
                  id={name}
                  name={name}
                  error={error}
                />
              );

            case "textarea":
              return (
                <TextField
                  multiline
                  helperText={helperText}
                  label={label}
                  value={value}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isReadOnly}
                  fullWidth
                  key={index}
                  InputLabelProps={{ shrink: shrinkLabel }}
                  color="secondary"
                  variant="outlined"
                  type="text"
                  id={name}
                  name={name}
                  error={error}
                />
              );

            case "switch":
              return (
                <Field name={name}>
                  {({ field, form }) => (
                    <>
                      <Typography
                        id={name}
                        gutterBottom
                        sx={{ color: isReadOnly ? "text.disabled" : "inherit" }}
                      >
                        {label}
                      </Typography>
                      {/* <Slider max={10} min={0} /> */}
                      <Switch
                        disabled={isReadOnly}
                        onChange={(event, newValue) =>
                          form.setFieldValue(field.name, newValue)
                        }
                        value={Boolean(value)}
                        checked={Boolean(value)}
                      />
                    </>
                  )}
                </Field>
              );

            case "select":
              return (
                <FormControl
                  label={label}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isReadOnly}
                  fullWidth
                  key={index}
                  color="secondary"
                  variant="outlined"
                  type="text"
                  id={name}
                  name={name}
                  error={error}
                >
                  <InputLabel>{label}</InputLabel>
                  <Select
                    native
                    value={value}
                    label={label}
                    inputProps={{
                      name,
                      id: name,
                    }}
                  >
                    <option value="" disabled>
                      {placeholder}
                    </option>
                    {val?.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                  {error && <FormHelperText>{helperText}</FormHelperText>}
                </FormControl>
              );

            case "radio":
              return (
                <FormControl
                  label={label}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isReadOnly}
                  fullWidth
                  key={index}
                  color="secondary"
                  variant="outlined"
                  type="text"
                  error={error}
                >
                  <FormLabel component="legend">{label}</FormLabel>
                  <RadioGroup id={name} name={name} value={value}>
                    {val?.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        value={option.value}
                        control={<Radio color="secondary" />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              );

            case "multiselect":
              return (
                <FormControl
                  label={label}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isReadOnly}
                  fullWidth
                  key={index}
                  color="secondary"
                  variant="outlined"
                  type="text"
                  error={error}
                >
                  <InputLabel shrink={shrinkLabel}>{label}</InputLabel>
                  <Select
                    multiple
                    native
                    value={String(value).split(",")}
                    label={label}
                    inputProps={{
                      name,
                      id: name,
                    }}
                  >
                    {val?.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                  {error && <FormHelperText>{helperText}</FormHelperText>}
                </FormControl>
              );

            case "checkbox":
              return (
                <Field name={name}>
                  {({ field }) => (
                    <FormControlLabel
                      disabled={isReadOnly}
                      control={
                        <Checkbox
                          {...field}
                          id={name}
                          color="secondary"
                          checked={Boolean(value)}
                        />
                      }
                      label={label}
                      labelPlacement="end"
                    />
                  )}
                </Field>
              );

            case "slider":
              return (
                <Field name={name}>
                  {({ field, form }) => (
                    <>
                      <Typography
                        id={name}
                        gutterBottom
                        sx={{ color: isReadOnly ? "text.disabled" : "inherit" }}
                      >
                        {label}
                      </Typography>
                      {/* <Slider max={10} min={0} /> */}
                      <Slider
                        disabled={isReadOnly}
                        value={Number(value) ? Number(value) : 0}
                        onChange={(event, newValue) =>
                          form.setFieldValue(field.name, newValue)
                        }
                        valueLabelDisplay="auto"
                        aria-label="Default"
                        marks
                        defaultValue={1}
                        step={1}
                        min={0}
                        max={10}
                      />
                    </>
                  )}
                </Field>
              );

            case "autocomplete":
              return (
                <Field name={name}>
                  {({ field, form }) => (
                    <Autocomplete
                      disabled={isReadOnly}
                      key={index}
                      options={val}
                      getOptionLabel={(option) => option.label}
                      value={value}
                      onChange={(event, newValue) =>
                        form.setFieldValue(field.name, newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          defaultValue=" "
                          fullWidth
                          label={label}
                          variant="outlined"
                          color="secondary"
                          error={error}
                          helperText={helperText}
                        />
                      )}
                    />
                  )}
                </Field>
              );

            case "multiautocomplete":
              // Pour empêcher l'utilisateur de saisir à nouveau une valeur qu'il à déjà sauvegardé (pour éviter les doublons)
              const selectedValue = Array.isArray(value) ? value : [value];
              const filteredOptions = val?.filter(
                (option) =>
                  !selectedValue.some((item) => item.value === option.value)
              );

              return (
                <Field name={name}>
                  {({ field, form }) => (
                    <Autocomplete
                      multiple
                      disabled={isReadOnly}
                      key={index}
                      options={filteredOptions}
                      getOptionLabel={(option) => option.label}
                      value={value ? value : []}
                      onChange={(event, newValue) =>
                        form.setFieldValue(field.name, newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          defaultValue=" "
                          fullWidth
                          label={label}
                          variant="outlined"
                          color="secondary"
                          error={error}
                          helperText={helperText}
                        />
                      )}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            key={index}
                            label={option.label}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  )}
                </Field>
              );

            // Ajoutez d'autres cas pour les autres types de champs MUI

            default:
              return null;
          }
        })()}
      </Box>
    )
  );
};

const ObjetsForm = (props) => {
  const userObjetSelected = useSelector(selectUserObjetSelected);
  const isEditingObjet = useSelector(selectIsEditingObjet);

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [addNewObjet, { isLoadingAddNew }] = useAddNewObjetMutation();
  const [updateObjet, { isLoadingUpdate }] = useUpdateObjetMutation();

  const handleFormSubmit = async (values) => {
    if (!isLoadingAddNew && !isLoadingUpdate) {
      if (isEditingObjet) {
        try {
          await updateObjet({
            user_section_id: props.userSection.id,
            data: values,
          }).unwrap();
          toast.success(cnf.TXT.TOAST_SUCCESS);
        } catch (err) {
          toast.error(cnf.TXT.TOAST_ERROR);
        }
      } else {
        try {
          await addNewObjet({
            user_section_id: props.userSection.id,
            data: values,
          }).unwrap();
          props.emptyObjet();
          toast.success(cnf.TXT.TOAST_SUCCESS);
        } catch (err) {
          toast.error(cnf.TXT.TOAST_ERROR);
        }
      }
    }
  };

  // Nécéssaire pour afficher les fx déjà renseigné
  // Bug :
  //    si le champ est déjà présent dans l'objet en bdd :
  //        le removeFX empêche la màj des valeurs lorsqu'on saisi une valeur dans le champ seulement
  //        bizzarement ça envoi tout de meme le dernier caractère (à la suite de la valeur courante)
  //    si le champ n'est pas encore présent dans l'objet en bdd :
  //        le removeFx n'empeche pas la saisie de la valeur
  // Résolution :
  //    Dans objetsSlice.js > updateObjet ET > addNewObjet, remplacer "body : data" par "
  //    body: {
  //         ...data,
  //         fx: data,
  //       },"

  function removeFx(string) {
    if (string !== "id" && string !== "name" && string.startsWith("fx.")) {
      return string.substring(3); // Supprimer le préfixe 'fx.'
    }
    return string;
  }

  const formikRef = createRef();
  useEffect(() => {
    // Réinitialiser le formulaire lorsque les initialValues changent
    formikRef.current.resetForm({ values: userObjetSelected });
  }, [userObjetSelected]);

  /** Pour que la section prenne 100% de la hauteur de l'écran restant */
  const [containerHeight, setContainerHeight] = useState(`calc(100vh - 75px)`); // Hauteur initiale à 100vh
  useEffect(() => {
    const handleResize = () => {
      setContainerHeight(`calc(${window.innerHeight} - 75px)`); // Met à jour la hauteur du conteneur en fonction de la taille de la fenêtre
    };

    window.addEventListener("resize", handleResize); // Ajoute un écouteur d'événement pour la redimension de la fenêtre

    return () => {
      window.removeEventListener("resize", handleResize); // Nettoie l'écouteur d'événement lors du démontage du composant
    };
  }, []);

  const fields =
    props.userSection.section.structure.objet?.fields ??
    props.userSection.section.structure;

  return (
    <section style={{ height: containerHeight, overflow: "auto" }}>
      <Box
        style={{ maxHeight: "100%", overflow: "auto" }}
        sx={{ ml: 0, pl: 2, pr: 2, pb: 2, pt: 1 }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={userObjetSelected}
          validationSchema={checkoutSchema}
          innerRef={formikRef}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? "span 4" : "span 4" },
                }}
              >
                {fields.map((field, index) => (
                  <ObjetField
                    isReadOnly={
                      !(isEditingObjet === userObjetSelected.id) &&
                      !(isEditingObjet === 0)
                    }
                    key={index}
                    type={field.type}
                    label={field.label}
                    name={removeFx(field.name)}
                    value={values[removeFx(field.name)]}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    error={
                      touched[removeFx(field.name)] &&
                      errors[removeFx(field.name)]
                    }
                    helperText={
                      touched[removeFx(field.name)] &&
                      errors[removeFx(field.name)]
                    }
                    val={field.val}
                  />
                ))}
              </Box>
              {(isEditingObjet === userObjetSelected.id ||
                isEditingObjet === 0) && (
                <Box display="flex" justifyContent="end" mt="20px">
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    endIcon={<ModeEditOutlineIcon />}
                  >
                    {isEditingObjet ? "Modifier" : "Ajouter"}
                  </Button>
                </Box>
              )}
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? "span 4" : "span 4" },
                }}
              ></Box>
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
              />
            </form>
          )}
        </Formik>
      </Box>
    </section>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  // name: yup.string().required("required"),
  // price: yup.string().required("required"),
  // firstName: yup.string().required("required"),
  // lastName: yup.string().required("required"),
  // email: yup.string().email("invalid email").required("required"),
  // contact: yup
  //   .string()
  //   .matches(phoneRegExp, "Phone number is not valid")
  //   .required("required"),
  // address1: yup.string().required("required"),
  // address2: yup.string().required("required"),
});

export default ObjetsForm;
