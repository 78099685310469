import { Box } from "@mui/material";
import Header from "@/components/Header";
import YourComponent from "./YourComponent";

const Dashboard = () => {
  return (
    <Box m="20px">
      <Header title="DASHBOARD" subtitle="To be created" />
      <YourComponent />
    </Box>
  );
};

export default Dashboard;
