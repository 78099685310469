import { Box, IconButton, useTheme } from "@mui/material";
import { useContext, useEffect } from "react";
import { ColorModeContext, tokens } from "@/theme";
import InputBase from "@mui/material/InputBase";
import useMediaQuery from "@mui/material/useMediaQuery";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import { accountService, userService } from "@/_services";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {
  setConnected,
  setDisconnected,
  setUserApplicationSelected,
  selectEmail,
  selectUserApplications,
  selectUserApplicationSelected,
} from "@/state/userSlice";

/** ApplicationsDialogRaw */
import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
/** ApplicationsDialogRaw */

/** Person Menu */
// import * as React from 'react';
// import Box from '@mui/material/Box';
// import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
// import Button from '@mui/material/Button';
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
/** Person Menu */

/** ApplicationsDialogRaw */
function ApplicationsDialogRaw(props) {
  const dispatch = useDispatch();
  const userApplications = useSelector(selectUserApplications);
  const userApplicationSelected = useSelector(selectUserApplicationSelected);
  const selectedUserApplication = useSelector(selectUserApplicationSelected);

  const { onClose, value: valueProp, open, ...other } = props;
  const [userApplicationSelection, setUserApplicationSelection] =
    React.useState(selectedUserApplication);
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    if (!open) {
      setUserApplicationSelection(
        userApplications?.find((item) => item.id == valueProp)
      );
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    if (userApplicationSelection != userApplicationSelected)
      dispatch(setUserApplicationSelected(userApplicationSelection));
    onClose(userApplicationSelection.id);
  };

  const handleChange = (event) => {
    setUserApplicationSelection(
      userApplications?.find((item) => item.id == event.target.value)
    );
  };

  const isLoading = !userApplications?.length;

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Sélectionnez une userApplication</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="userApplication"
          name="userApplication"
          value={userApplicationSelection?.id ?? ""} // using nullish coalescing operator (??) to avoid changing from uncontrolled to controlled value
          onChange={handleChange}
        >
          {isLoading
            ? // Afficher le loader tant que le tableau d'objets est vide
              "Loading..."
            : userApplications?.map((userApplication) => (
                <FormControlLabel
                  value={userApplication.id}
                  key={userApplication.id}
                  control={<Radio />}
                  label={
                    userApplication?.application.name +
                    " - " +
                    userApplication.name
                  }
                />
              ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Annuler
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}
/** ApplicationsDialogRaw */

/** Person Menu */
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function PersonMenu() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const email = useSelector(selectEmail);

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt={email} src="/static/images/avatar/2.jpg" />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem key={setting} onClick={handleCloseUserMenu}>
            <Typography textAlign="center">{setting}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
/** Person Menu */

const Topbar = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  let navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    userService
      .getMe()
      .then((res) => {
        dispatch(setConnected(res.data));
      })
      .catch((err) => console.log(err));
  }, []);

  const onClicklogout = () => {
    accountService.logout();
    dispatch(setDisconnected());
    navigate("/auth/login");
  };

  /** ApplicationsDialogRaw */
  const [open, setOpen] = React.useState(true);
  const [value, setValue] = React.useState({}); // utile pour ne pas changer le focus radio au clic sur "Annuler"

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };
  /** ApplicationsDialogRaw */

  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {!isMobile && (
        <Box display="flex" p={0}>
          <Typography
            style={{ whiteSpace: "nowrap" }}
            variant={"h2"}
            color={colors.grey[100]}
            fontWeight="bold"
            sx={{ m: "0 0 5px 0" }}
          >
            {props.userSectionSelectedLabel}
          </Typography>
        </Box>
      )}

      <Box display="flex" justifyContent="space-between" p={0}>
        {/* SEARCH BAR */}
        {/* <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          borderRadius="3px"
          mr={2}
        >
          <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box> */}

        {/* ICONS */}
        <Box display="flex">
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
          <IconButton>
            <NotificationsOutlinedIcon />
          </IconButton>
          <IconButton onClick={handleOpenDialog}>
            <SettingsOutlinedIcon />
          </IconButton>
          <ApplicationsDialogRaw
            id="application-menu"
            keepMounted
            open={open}
            onClose={handleClose}
            value={value}
          />
          <IconButton onClick={onClicklogout}>
            <LogoutOutlinedIcon />
          </IconButton>
          <PersonMenu />
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
