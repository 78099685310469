import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { accountService } from "@/_services";

import { Alert, Box, Button, TextField } from "@mui/material";
import Header from "@/components/Header";

import "./auth.css";

import { useDispatch } from "react-redux";
import { setConnected } from "@/state/userSlice";

const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Simulez l'état de connexion en récupérant les informations depuis le stockage local, le cookie ou l'API
  useEffect(() => {
    setIsLoggedIn(accountService.isLogged());
  }, []);

  const [errorMessage, setErrorMessage] = useState("");

  // Attention ici mise en place de valeur par défaut pour travailler.
  // NE JAMAIS FAIRE CELA
  const [credentials, setCredentials] = useState({
    // username: "john@doe.fr",
    // password: "0000",
    username: "",
    password: "",
  });

  // Gestion de la modification des champs du formulaire
  const onChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  // Soumission du formulaire
  const onSubmit = (e) => {
    e.preventDefault();
    accountService
      .login(credentials)
      .then((res) => {
        // Sauvegarde du token
        accountService.saveToken(res.data.token);
        // Si les informations sont valides, met à jour l'utilisateur dans le store Redux
        const username = credentials.username;
        dispatch(setConnected(username));
        // Redirige vers la page /admin
        navigate("/admin", { replace: true });
      })
      .catch((error) => {
        console.log(error);
        if (!error?.response) {
          setErrorMessage("No Server Response");
        } else if (error.response?.status === 400) {
          setErrorMessage("Missing Username or Password");
        } else if (error.response?.status === 401) {
          setErrorMessage("Unauthorized");
        } else {
          setErrorMessage("Login Failed");
        }
      });
  };

  if (isLoggedIn) {
    // Si l'utilisateur est déjà connecté, redirigez-le vers l'espace connecté
    return navigate("/admin", { replace: true });
  }

  return (
    <Box
      sx={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        width: "300px",
      }}
    >
      <Header title="LOGIN" subtitle="Connectez-vous" />
      <form onSubmit={onSubmit}>
        <Box display="grid" gap="30px">
          <TextField
            type="text"
            name="username"
            value={credentials.username}
            onChange={onChange}
            fullWidth
            variant="filled"
            label="Email"
            sx={{ gridColumn: "span 2" }}
          />
          <TextField
            type="password"
            name="password"
            value={credentials.password}
            onChange={onChange}
            fullWidth
            variant="filled"
            label="Mot de passe"
            sx={{ gridColumn: "span 2" }}
          />
        </Box>
        <Box display="flex" justifyContent="end" mt="20px">
          <Button type="submit" color="secondary" variant="contained">
            Connexion
          </Button>
        </Box>
      </form>
      <Box display="flex" mt="20px">
        {errorMessage && (
          <Alert sx={{ width: "100%" }} severity="error">
            {errorMessage}
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default Login;
