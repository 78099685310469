import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const relationsAdapter = createEntityAdapter();

const initialState = relationsAdapter.getInitialState();

export const relationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRelations: builder.query({
      query: ({ user_section_id, section_id }) =>
        `/user_sections/${user_section_id}/relations/${section_id}`,
      transformResponse: (responseData) => {
        return relationsAdapter.setAll(
          initialState,
          responseData["hydra:member"][0]["links"]
        );
      },
      providesTags: (result, error, arg) => [
        { type: "Relation", id: "LIST" },
        ...result.ids.map((id) => ({ type: "Relation", id })),
      ],
    }),
    // addNewRelation: builder.mutation({
    //   query: ({ user_section_id, data }) => ({
    //     url: `/user_sections/${user_section_id}/relation/bidon`,
    //     method: "POST",
    //     body: {
    //       ...data,
    //       fx: data,
    //     },
    //   }),
    //   invalidatesTags: [{ type: "Relation", id: "LIST" }],
    // }),
    // updateRelation: builder.mutation({
    //   query: ({ user_section_id, data }) => ({
    //     url: `/user_sections/${user_section_id}/relation/bidon`,
    //     method: "PUT",
    //     body: {
    //       ...data,
    //       fx: data,
    //     },
    //   }),
    //   invalidatesTags: (result, error, arg) => [
    //     { type: "Relation", id: arg.id },
    //   ],
    // }),
    // deleteRelation: builder.mutation({
    //   query: ({ user_section_id, id }) => ({
    //     url: `/user_sections/${user_section_id}/relation/${id}`,
    //     method: "DELETE",
    //     body: id,
    //   }),
    //   invalidatesTags: (result, error, arg) => [
    //     { type: "Relation", id: arg.id },
    //   ],
    // }),
    reorderRelations: builder.mutation({
      query: ({
        user_section_id,
        section_id,
        source_categorie_id,
        destination_categorie_id,
        reordered_source_ids,
        reordered_destination_ids,
      }) => ({
        url: `/user_sections/${user_section_id}/relations/${section_id}/reorder`,
        method: "POST",
        body: {
          source_categorie_id,
          destination_categorie_id,
          reordered_source_ids,
          reordered_destination_ids,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Relation", id: arg.id },
      ],
    }),
    unlinkRelations: builder.mutation({
      query: ({ user_section_id, section_id, categorie_id, item_id }) => ({
        url: `/user_sections/${user_section_id}/relations/${section_id}/unlink`,
        method: "POST",
        body: { categorie_id, item_id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Relation", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetRelationsQuery,
  // useAddNewRelationMutation,
  // useUpdateRelationMutation,
  // useDeleteRelationMutation,
  useReorderRelationsMutation,
  useUnlinkRelationsMutation,
} = relationsApiSlice;
