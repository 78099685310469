import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "@/components/Header";

const Dashboard = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  return (
    <Box m="20px">
      <Header title="Oups !" subtitle="Erreur 404" />
    </Box>
  );
};

export default Dashboard;
