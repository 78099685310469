import React from "react";
import { Routes, Route } from "react-router-dom";

import { AdminLayout } from "@/pages/Admin";

import Dashboard from "@/pages/Admin/dashboard";
import Form from "@/pages/Admin/form";
import UserSection from "@/pages/Admin/userSection";
import Team from "@/pages/Admin/team";
// import Invoices from "@/pages/Admin/invoices";
// import Contacts from "@/pages/Admin/contacts";
// import Bar from "@/pages/Admin/bar";
// import Line from "@/pages/Admin/line";
// import Pie from "@/pages/Admin/pie";
// import FAQ from "@/pages/Admin/faq";
// import Geography from "@/pages/Admin/geography";
// import Calendar from "@/pages/Admin/calendar/calendar";

import Error from "@/_utils/Error";

import { useSelector } from "react-redux";
import { selectUserApplicationSelected } from "@/state/userSlice";

const AdminRouter = () => {
  const userApplicationSelected = useSelector(selectUserApplicationSelected); //['test1', 'test2']

  return (
    <Routes>
      <Route element={<AdminLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        {userApplicationSelected?.userSections.map((userSection) => (
          <Route
            key={userSection.id}
            path={"/" + userSection.id}
            element={<UserSection userSection={userSection} />}
          />
        ))}
        <Route path="/form" element={<Form />} />
        <Route path="/team" element={<Team />} />
        {/* <Route path="/contacts" element={<Contacts />} />
        <Route path="/invoices" element={<Invoices />} />
        <Route path="/form" element={<Form />} />
        <Route path="/bar" element={<Bar />} />
        <Route path="/pie" element={<Pie />} />
        <Route path="/line" element={<Line />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/geography" element={<Geography />} /> */}
        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  );
};

export default AdminRouter;
