import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ObjetsListReorderable from "@/features/objets/ObjetsListReorderable";
import RelationsListReorderable from "@/features/relations/RelationsListReorderable";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RelationsTabs = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const heightAdjustment = " - 48px - 18px";

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          <Tab label="" {...a11yProps(0)} icon={<FormatListBulletedIcon />} />
          {props.relations?.map((relation, index) => (
            <Tab
              label={
                relation.label && relation.label != ""
                  ? relation.label
                  : "Par catégorie"
              }
              {...a11yProps(index + 1)}
            />
          ))}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ObjetsListReorderable
          userSection={props.userSection}
          emptyObjet={props.emptyObjet}
          handleAddObjetClick={props.handleAddObjetClick}
          heightAdjustment={heightAdjustment}
        />
      </CustomTabPanel>
      {props.relations?.map((relation, index) => (
        <CustomTabPanel value={value} index={index + 1}>
          <RelationsListReorderable
            userSection={props.userSection}
            emptyObjet={props.emptyObjet}
            handleAddObjetClick={props.handleAddObjetClick}
            to_section_id={relation.to_section_id}
            heightAdjustment={heightAdjustment}
          />
        </CustomTabPanel>
      ))}
    </Box>
  );
};

export default RelationsTabs;
