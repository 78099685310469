import { useState, useEffect } from "react";
import cnf from "@/config.json";
import { useGetObjetsQuery } from "./objetsSlice";
import {
  List,
  ListItem,
  Paper,
  ListItemButton,
  ListItemText,
  IconButton,
  Box,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable as Droppable } from "@/_helpers/StrictModeDroppable";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserObjetSelected,
  selectUserObjetSelected,
  setIsEditingObjet,
} from "@/state/userSlice";
import {
  useDeleteObjetMutation,
  useReorderObjetsMutation,
} from "./objetsSlice";

const ObjetsListReorderable = (props) => {
  const dispatch = useDispatch();
  const userObjetSelected = useSelector(selectUserObjetSelected);

  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleListObjetClick = (event, index, objet) => {
    if (objet != userObjetSelected) dispatch(setUserObjetSelected(objet));
    setSelectedIndex(index);
    dispatch(setIsEditingObjet(false));
  };

  const handleEditObjetClick = (event, index, objet) => {
    dispatch(setIsEditingObjet(objet.id));
  };

  const [deleteObjet] = useDeleteObjetMutation();

  const handleDeleteObjetClick = async (event, index, objet) => {
    try {
      await deleteObjet({
        user_section_id: props.userSection.id,
        id: userObjetSelected.id,
      }).unwrap();
      props.emptyObjet();
      toast.success(cnf.TXT.TOAST_SUCCESS);
    } catch (err) {
      toast.error(cnf.TXT.TOAST_ERROR);
    }
  };

  const handleAddObjetClick = () => {
    setSelectedIndex(0);
    props.handleAddObjetClick();
  };

  const {
    data: objets,
    refetch,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetObjetsQuery(props.userSection?.id);

  const deleteCondition = true;

  const [objetsReordered, setObjetsReordered] = useState(objets || []);

  useEffect(() => {
    setObjetsReordered(objets);
  }, [objets]);

  const [reorderObjetsMutation] = useReorderObjetsMutation();

  const handleOnDragEnd = async (result) => {
    setIsDragging(false);

    // Manipulation de l'ordre des items et des références de parent en fonction du résultat du glisser-déposer
    // Met à jour les données dans le backend via une mutation RTK Query
    // Recharge les données après la mise à jour pour refléter les changements à l'écran

    if (!result.destination) {
      return; // Le glisser-déposer n'a pas abouti dans une zone de dépôt valide
    }

    const { source, destination } = result;
    if (source.index === destination.index) {
      return; // L'ordre n'a pas changé
    }

    // Réorganiser les éléments dans la liste en fonction du glisser-déposer
    const reorderedIds = Array.from(objetsReordered.ids);
    const [removed] = reorderedIds.splice(source.index, 1);
    reorderedIds.splice(destination.index, 0, removed);

    // Mettre à jour l'état pour garder l'affichage réordonné
    const previous_objetsReordered = objetsReordered;
    setObjetsReordered({
      ids: reorderedIds,
      entities: objets.entities,
    });

    try {
      await reorderObjetsMutation({
        user_section_id: props.userSection.id,
        data: reorderedIds,
      }).unwrap();
      toast.success(cnf.TXT.TOAST_SUCCESS);
    } catch (err) {
      console.log(err);
      setObjetsReordered(previous_objetsReordered); // pour afficher l'ancien ordre vu que l'API tombe en erreur
      toast.error(cnf.TXT.TOAST_ERROR);
    }
  };

  /** Pour que la section prenne 100% de la hauteur de l'écran restant */
  const heightAdjustment = props.heightAdjustment ?? "";
  const [containerHeight, setContainerHeight] = useState(
    `calc(100vh - 128px${heightAdjustment})`
  ); // Hauteur initiale à 100vh
  useEffect(() => {
    const handleResize = () => {
      // setContainerHeight(`${window.innerHeight - 130}px`); // Met à jour la hauteur du conteneur en fonction de la taille de la fenêtre
      setContainerHeight(
        `calc(${window.innerHeight} - 128px${heightAdjustment})`
      ); // Met à jour la hauteur du conteneur en fonction de la taille de la fenêtre
    };

    window.addEventListener("resize", handleResize); // Ajoute un écouteur d'événement pour la redimension de la fenêtre

    return () => {
      window.removeEventListener("resize", handleResize); // Nettoie l'écouteur d'événement lors du démontage du composant
    };
  }, []);

  /** Pour qu'on puisse scroll dans la liste pendant qu'on drag and drop   */
  const [isDragging, setIsDragging] = useState(false);
  const handleOnDragStart = () => {
    setIsDragging(true);
  };

  const displayValues = (objet) => {
    let result = "";
    for (let key in objet) {
      if (objet.hasOwnProperty(key)) {
        result += key + ": " + objet[key] + " | ";
      }
    }

    return result;
  };

  let content;
  if (isLoading) {
    content = <p>Loading...</p>;
  } else if (isSuccess) {
    content = (
      <>
        <Box display="flex" justifyContent="end" mb="20px">
          <Button
            type="submit"
            color="secondary"
            variant="outlined"
            disabled={selectedIndex === 0 ? true : false}
            endIcon={<AddIcon />}
            onClick={handleAddObjetClick}
          >
            Ajouter
          </Button>
        </Box>
        <DragDropContext
          onDragStart={handleOnDragStart}
          onDragEnd={handleOnDragEnd}
        >
          <section
            style={{ height: containerHeight, overflow: "auto" }}
            onScroll={(e) => e.stopPropagation()}
          >
            <Droppable droppableId={`objets`}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <List
                    className={isDragging ? "disable-scroll" : ""}
                    style={{ maxHeight: "100%", overflow: "auto" }}
                    dense={true}
                  >
                    {objetsReordered?.ids.map((objetIndex, index) => (
                      <Draggable
                        key={objetIndex}
                        draggableId={`objet-${objetIndex}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Paper elevation={0} sx={{ mb: 0.5 }}>
                              <ListItem
                                disablePadding
                                key={objetIndex}
                                {...(deleteCondition &&
                                  selectedIndex === objetIndex && {
                                    secondaryAction: (
                                      <>
                                        <IconButton
                                          edge="end"
                                          aria-label="edit"
                                          sx={{ ml: 2 }}
                                          onClick={(event) =>
                                            handleEditObjetClick(
                                              event,
                                              objetIndex,
                                              objets.entities[objetIndex]
                                            )
                                          }
                                        >
                                          <ModeEditOutlineIcon />
                                        </IconButton>
                                        <IconButton
                                          edge="end"
                                          aria-label="delete"
                                          sx={{ ml: 2 }}
                                          onClick={(event) =>
                                            handleDeleteObjetClick(
                                              event,
                                              objetIndex,
                                              objets.entities[objetIndex]
                                            )
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </>
                                    ),
                                  })}
                              >
                                <ListItemButton
                                  selected={selectedIndex === objetIndex}
                                  onClick={(event) =>
                                    handleListObjetClick(
                                      event,
                                      objetIndex,
                                      objets.entities[objetIndex]
                                    )
                                  }
                                >
                                  <ListItemText
                                    sx={{ mr: 3 }}
                                    primary={displayValues(
                                      objets.entities[objetIndex]
                                    )}
                                  />
                                </ListItemButton>
                              </ListItem>
                            </Paper>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                </div>
              )}
            </Droppable>
          </section>
        </DragDropContext>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    );
  } else if (isError) {
    content = <p>{error}</p>;
  }

  return content;
};

export default ObjetsListReorderable;
