import { useEffect } from "react";
import { Box, Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import ObjetsList from "@/features/objets/ObjetsList";
import ObjetsListReorderable from "@/features/objets/ObjetsListReorderable";
import RelationsListReorderable from "@/features/relations/RelationsListReorderable";
// import RelationsListReorderable2 from "@/features/relations/RelationsListReorderable2";
import ObjetsForm from "@/features/objets/ObjetsForm";
import { useDispatch } from "react-redux";
import {
  setUserObjetSelected,
  setIsEditingObjet,
  setUserSectionSelected,
} from "@/state/userSlice";
import RelationsTabs from "@/features/relations/RelationsTabs";

const UserSection = (props) => {
  const dispatch = useDispatch();

  const isNonMobile = useMediaQuery("(min-width:600px)");

  // Nécessaire pour que les champs fx puissent être reconnu pour saisir les valeurs  envoyer => sinon champ inutilisable (non envoyé ou non focusable)
  function removeFx(value) {
    if (value.startsWith("fx.")) {
      return value.substring(3); // Supprimer le préfixe 'fx.'
    }
    return value;
  }

  function emptyObjet() {
    const fields =
      props.userSection.section.structure.objet?.fields ??
      props.userSection.section.structure;

    const emptyObjet = {};
    fields.map((field) => {
      emptyObjet[removeFx(field.name)] = "";
    });
    dispatch(setUserObjetSelected(emptyObjet));
    dispatch(setIsEditingObjet(0));
  }

  // Initialiser l'objet
  useEffect(() => {
    dispatch(setUserSectionSelected(props.userSection));
    emptyObjet();
  }, []);

  const handleAddObjetClick = () => {
    emptyObjet();
  };

  const relations =
    typeof props.userSection.section.structure.relation !== "undefined"
      ? props.userSection.section.structure.relation
      : null;

  return (
    <Box m="20px" mt="0px" mb="0px">
      <Box
        display="grid"
        gap="10px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? "span 2" : "span 4" },
        }}
      >
        <Box>
          {relations && Object.keys(relations).length > 0 && (
            <RelationsTabs
              relations={relations}
              userSection={props.userSection}
              emptyObjet={emptyObjet}
              handleAddObjetClick={handleAddObjetClick}
            />
          )}
          {!relations && (
            <ObjetsListReorderable
              userSection={props.userSection}
              emptyObjet={emptyObjet}
              handleAddObjetClick={handleAddObjetClick}
            />
          )}
        </Box>
        <Box>
          <ObjetsForm userSection={props.userSection} emptyObjet={emptyObjet} />
        </Box>
      </Box>
    </Box>
  );
};

export default UserSection;
