import { Routes, Route } from "react-router-dom";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "@/theme";

import AdminRouter from "@/pages/Admin/AdminRouter";
import AuthRouter from "@/pages/Auth/AuthRouter";
import AuthGuard from "@/_helpers/AuthGuard";
import Home from "@/pages/Home";
import Error from "@/_utils/Error";

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/admin/*"
              element={
                <AuthGuard>
                  <AdminRouter />
                </AuthGuard>
              }
            />
            <Route path="/auth/*" element={<AuthRouter />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
