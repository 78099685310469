import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const objetsAdapter = createEntityAdapter();

const initialState = objetsAdapter.getInitialState();

export const objetsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getObjets: builder.query({
      query: (user_section_id) =>
        `/user_sections/${user_section_id}/objets/bidon`,
      transformResponse: (responseData) => {
        return objetsAdapter.setAll(initialState, responseData["hydra:member"]);
      },
      providesTags: (result, error, arg) => [
        { type: "Objet", id: "LIST" },
        ...result.ids.map((id) => ({ type: "Objet", id })),
      ],
    }),
    addNewObjet: builder.mutation({
      query: ({ user_section_id, data }) => ({
        url: `/user_sections/${user_section_id}/objet/bidon`,
        method: "POST",
        body: {
          ...data,
          fx: data,
        },
      }),
      invalidatesTags: [{ type: "Objet", id: "LIST" }],
    }),
    updateObjet: builder.mutation({
      query: ({ user_section_id, data }) => ({
        url: `/user_sections/${user_section_id}/objet/bidon`,
        method: "PUT",
        body: {
          ...data,
          fx: data,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Objet", id: arg.id }],
    }),
    deleteObjet: builder.mutation({
      query: ({ user_section_id, id }) => ({
        url: `/user_sections/${user_section_id}/objet/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Objet", id: arg.id }],
    }),
    reorderObjets: builder.mutation({
      query: ({ user_section_id, data }) => ({
        url: `/user_sections/${user_section_id}/objets/reorder`,
        method: "POST",
        body: { data },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Objet", id: arg.id }],
    }),
  }),
});

export const {
  useGetObjetsQuery,
  useAddNewObjetMutation,
  useUpdateObjetMutation,
  useDeleteObjetMutation,
  useReorderObjetsMutation,
} = objetsApiSlice;
